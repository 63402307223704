import { sanitize } from "isomorphic-dompurify";
import React from "react";

interface HeadlineProps {
  headline: string;
  htype_tag: "h2" | "h3";
  className?: string;
}

const Headline = React.forwardRef<HTMLHeadingElement, HeadlineProps>(
  ({ headline, htype_tag, className = "" }, ref) => {
    return htype_tag === "h2" ? (
      <h2 ref={ref} className={className}>
        <strong
          dangerouslySetInnerHTML={{
            __html: sanitize(headline),
          }}
        />
      </h2>
    ) : (
      <h3 ref={ref} className={className}>
        <strong
          dangerouslySetInnerHTML={{
            __html: sanitize(headline),
          }}
        />
      </h3>
    );
  }
);

Headline.displayName = "Headline";
export default Headline;
